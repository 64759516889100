import { render, staticRenderFns } from "./ListAdvertiserInvoice.vue?vue&type=template&id=7325c64d"
import script from "./ListAdvertiserInvoice.vue?vue&type=script&lang=js"
export * from "./ListAdvertiserInvoice.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QTd,QBtn,QSelect,QPagination});
